<template>
  <form>
    <v-row class="py-0">
      <v-col cols="12" class="py-0">
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Tax Type</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-select
              v-model="form.type"
              :items="items"
              :error-messages="selectErrors"
              outlined
              dense
              flat
              required
              background-color="white"
              @change="$v.form.type.$touch()"
              @blur="$v.form.type.$touch()"
              ref="type"
              @input="onChangeSelect"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="py-0" v-if="incomeTax">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>{{ label }}</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-select
              v-model="form.incomeTaxArticleType"
              :items="incomeTaxArticleTypes"
              :error-messages="selectErrors"
              outlined
              dense
              flat
              required
              background-color="white"
              @change="$v.form.incomeTaxArticleType.$touch()"
              @blur="$v.form.incomeTaxArticleType.$touch()"
              ref="incomeTaxArticleType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Description</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.description"
              :error-messages="descriptionErrors"
              :counter="255"
              outlined
              dense
              flat
              required
              background-color="white"
              @input="$v.form.description.$touch()"
              @blur="$v.form.description.$touch()"
              ref="description"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0" v-if="percentage">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Percentage</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.percentage"
              :error-messages="percentageErrors"
              required
              outlined
              dense
              flat
              suffix="%"
              type="number"
              background-color="white"
              @input="$v.form.percentage.$touch()"
              @blur="$v.form.percentage.$touch()"
              ref="percentage"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0" v-if="form.type !== 5">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Sales Tax Account</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-autocomplete
              v-model="form.salesAccountTax"
              :error-messages="salesErrors"
              :items="loadItems"
              :loading="isLoading"
              :search-input.sync="searchSales"
              item-text="Description"
              item-value="API"
              cache-items
              outlined
              dense
              flat
              clearable
              background-color="white"
              append-icon="mdi-magnify"
              placeholder="Start typing to Search"
              @input="$v.form.salesAccountTax.$touch()"
              @blur="$v.form.salesAccountTax.$touch()"
              ref="salesAccountTax"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Purchase Tax Account</v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-autocomplete
              v-model="form.purchaseAccountTax"
              :error-messages="purchaseErrors"
              :items="loadItems"
              :loading="isLoading"
              :search-input.sync="searchPurchase"
              item-text="Description"
              item-value="API"
              cache-items
              outlined
              dense
              flat
              clearable
              background-color="white"
              append-icon="mdi-magnify"
              placeholder="Start typing to Search"
              @input="$v.form.purchaseAccountTax.$touch()"
              @blur="$v.form.purchaseAccountTax.$touch()"
              ref="purchaseAccountTax"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-0">
      <v-col class="py-0 text-right" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, numeric } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    form: {
      type: { required },
      incomeTaxArticleType: { required },
      description: { required, maxLength: maxLength(255) },
      percentage: { required, numeric },
      salesAccountTax: { required },
      purchaseAccountTax: { required },
    },
  },

  data: () => ({
    defaultForm: {
      type: null,
      incomeTaxArticleType: null,
      description: "",
      percentage: 0,
      salesAccountTax: null,
      purchaseAccountTax: null,
    },
    form: {},
    items: [
      {
        text: "---Select Type---",
        value: null,
      },
      {
        text: "Value Added Tax",
        value: 1,
      },
      {
        text: "Luxury Goods Addition Tax",
        value: 2,
      },
      {
        text: "Income Tax Article 4(2)",
        value: 3,
      },
      {
        text: "Income Tax Article 15",
        value: 4,
      },
      {
        text: "Income Tax Article 21",
        value: 5,
      },
      {
        text: "Income Tax Article 22",
        value: 6,
      },
      {
        text: "Income Tax Article 23",
        value: 7,
      },
    ],
    incomeTaxArticleTypes: [
      {
        text: "---Select Type---",
        value: null,
      },
      {
        text: "Value Added Tax",
        value: 1,
      },
      {
        text: "Luxury Goods Addition Tax",
        value: 2,
      },
    ],
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    searchSales: null,
    searchPurchase: null,
    percentage: false,
    incomeTax: false,
  }),

  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.form.type.$dirty) return errors;
      !this.$v.form.type.required && errors.push("Type is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.maxLength &&
        errors.push("Description must be at most 255 characters long");
      !this.$v.form.description.required && errors.push("Description is required.");
      return errors;
    },
    percentageErrors() {
      const errors = [];
      if (!this.$v.form.percentage.$dirty) return errors;
      !this.$v.form.percentage.required && errors.push("Percentage is required");
      !this.$v.form.percentage.number && errors.push("Percentage must be number");
      return errors;
    },
    salesErrors() {
      const errors = [];
      if (!this.$v.form.salesAccountTax.$dirty) return errors;
      !this.$v.form.salesAccountTax.required && errors.push("Sales Tax Account is required");
      return errors;
    },
    purchaseErrors() {
      const errors = [];
      if (!this.$v.form.purchaseAccountTax.$dirty) return errors;
      !this.$v.form.purchaseAccountTax.required && errors.push("Purchase Tax Account is required");
      return errors;
    },
    loadItems() {
      return this.entries.map(entry => {
        const Description =
          entry.Description.length > this.descriptionLimit
            ? entry.Description.slice(0, this.descriptionLimit) + "..."
            : entry.Description;

        return Object.assign({}, entry, { Description });
      });
    },
    label() {
      if (this.form.type === 3) {
        return "Income Tax Article 4(2) Type";
      } else if (this.form.type === 4) {
        return "Tipe PPh 15";
      } else if (this.form.type === 7) {
        return "Income Tax Article 23 Type";
      } else {
        return "";
      }
    },
  },

  watch: {
    searchSales() {
      // Items have already been loaded
      if (this.loadItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch("https://api.publicapis.org/entries")
        .then(res => res.json())
        .then(res => {
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchPurchase() {
      // Items have already been loaded
      if (this.loadItems.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch("https://api.publicapis.org/entries")
        .then(res => res.json())
        .then(res => {
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },

  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key];
          if (input.includes("$")) return false;
          if (this.$v.form[input].$error) {
            this.$refs[input].focus();
            break;
          }
        }
      } else {
        console.log(this.form);
      }
    },
    clear() {
      this.$v.form.$reset();
      this.form = Object.assign({}, this.defaultForm);
      this.percentage = false;
      this.incomeTax = false;
    },
    onChangeSelect(val) {
      if (val === 1) {
        this.percentage = true;
        this.incomeTax = false;
        this.form.incomeTaxArticleTypes = null;
        this.form.percentage = 10;
      } else if (val === 2) {
        this.percentage = true;
        this.incomeTax = false;
        this.form.incomeTaxArticleTypes = null;
        this.form.percentage = 25;
      } else if (val === 3) {
        this.percentage = true;
        this.incomeTax = true;
        this.form.percentage = 10;
      } else if (val === 4) {
        this.percentage = true;
        this.incomeTax = true;
        this.form.percentage = 1.2;
      } else if (val === 5) {
        this.percentage = false;
        this.incomeTax = false;
        this.form.percentage = 0;
      } else if (val === 6) {
        this.percentage = true;
        this.incomeTax = false;
        this.form.percentage = 1.2;
      } else if (val === 7) {
        this.percentage = true;
        this.incomeTax = true;
        this.form.percentage = 15;
      } else {
        this.percentage = false;
        this.incomeTax = false;
        this.form.percentage = 0;
      }
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
